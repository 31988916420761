@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,700');

// brand colors
$primary: #00dcb4; // robin's egg blue
$danger: #ff494c; // danger
$light: #f2f0f0; // seashell
$grey-dark: #54565b; // abbey
$grey-darker: #424242; // tundora
$link: $grey-dark;
$alabaster: #f8f7f7;
$bombay: #a9aaad;
$supernova: #ffc900;

// global variables
$family-sans-serif: 'Roboto', sans-serif;
$family-monospace: 'Roboto-Mono', sans-serif;
$navbar-height: 6rem;

// component variables
//$control-border-width: 2px;
$body-background-color: $light;
$footer-background-color: #f2f0f0;
//$input-border-color: transparent;
$input-shadow: none;

$navbar-background-color: #ffffff;
$navbar-item-color: #ffffff;

//$box-padding: 4em 6em 2em 6em; // top, left, bottom, right
$box-radius: 0px;

$divider-background-color: $grey-dark;
$divider-thickness: 2px;

$tabs-border-bottom-color: rgba($grey-dark, 0.1);
$tabs-link-active-border-bottom-color: $grey-dark;

$box-shadow: 0 1px 5px 0 rgba(48, 40, 40, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);

$file-cta-background-color: #ffffff;

@import '~bulma/bulma.sass';

@import '~bulma-extensions/bulma-divider/dist/css/bulma-divider.sass';

$steps-maker-default-color: transparent;
$steps-marker-default-border: 1px solid rgba(48, 40, 40, 0.1);
$steps-default-color: rgba(48, 40, 40, 0.1);
$steps-completed-color: transparent;
$steps-active-color: transparent;

@import '~bulma-extensions/bulma-steps/dist/css/bulma-steps.sass';

@import '~bulma-extensions/bulma-switch/src/sass/index.sass';

.steps.is-outlined.is-small {
  .step-item {
    &:first-child::before {
      content: ' ';
      display: block;
      position: absolute;
    }

    &:before {
      top: 0.75rem;
      height: 1px;
      width: 100%;
      left: 50%;
      transform: translate(-50%);
      background: rgba(48, 40, 40, 0.1);
    }

    &.is-active {
      border-color: $grey-darker;
      &::before {
        background: $grey-darker;
      }
      .step-marker {
        border-color: $grey-darker;
        color: $grey-darker;
      }
    }

    &.is-completed {
      border-color: $primary;
      &::before {
        background: $primary;
      }
      .step-marker {
        border-color: $primary;
        color: $primary;
      }
    }
  }
}

@import '~@creativebulma/bulma-tooltip/src/sass/index.sass';

:root {
  --color-primary: #{$primary};
  --color-danger: #{$danger};
  --color-light: #{$light};
  --color-grey-dark: #{$grey-dark};
  --color-grey-darker: #{$grey-darker};
  --color-alabaster: #{$alabaster};
  --color-bombay: #{$bombay};
  --color-supernova: #{$supernova};
}

.tabs li:not(.is-active) {
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

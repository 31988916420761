html {
  overflow: hidden;
}

body {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1 1 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-end {
  align-items: flex-end;
}
.v-center {
  align-items: center;
}
.h-center {
  justify-content: center;
}
.h-right {
  justify-content: flex-end;
}
.h-between {
  justify-content: space-between;
}
.is-stacked {
  flex-direction: column;
}
.fillheight {
  height: 100%;
}
.fillwidth {
  width: 100%;
}

.field.is-grouped > .control {
  flex: none;
  width: 50%;
}

@media (max-width: 768px) {
  .reverse-column-on-mobile {
    display: flex;
    flex-direction: column-reverse;
  }

  .touch-min-50vh {
    min-height: 50vh;
  }
}

.input-paddingfull {
  position: relative !important;
  min-width: 100% !important;
  height: 3.5em !important;
  padding: calc(0.875em - 1px) calc(1em - 1px) !important;
}

.select:not(.is-multiple) {
  height: unset;
}

.StripeElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: calc(0.875em - 1px) calc(1em - 1px);
}
.StripeElement > * {
  transform: translateY(25%);
}

.StripeElement--invalid {
  box-shadow: 0 0 2px red;
}

.modal {
  flex-direction: column;
}

.top-ten-list .column {
  padding: 0.75rem 0 0 0.75rem !important;
  font-size: 14px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.contact-item:hover {
  background-color: #f5f5f5;
}

.is-borderless {
  border: none !important;
}

.grow {
  flex-grow: 1;
}

.shrink {
  flex-shrink: 1;
}

.is-wrapful {
  flex-wrap: wrap;
}

.underline {
  text-decoration: underline;
}

summary:focus {
  outline: none;
  cursor: pointer;
}

.offscreen {
  position: absolute;
  z-index: -99999;
  left: -100vw;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fade-out 600ms forwards;
}

.inset {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
